.root {
  display: flex;
  justify-content: end;
  column-gap: 20px;
}

.header {
  align-self: center;
  text-align: right;
}

.exit_btn {
  padding: 0 10px;
}